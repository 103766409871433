import React from 'react';
import Img from 'gatsby-image';
import Chip from '../chip/Chip';

export default function Player(props) {
  let url;
  if (props.playerid) {
    url = "https://steamcommunity.com/id/" + props.playerid;
  } else {
    url = "https://steamcommunity.com/profiles/" + props.profile;
  }
  return (
    <div className="player">
      <a href={url} target="_blank" rel="noopener noreferrer">
        <p className="player-name">{props.name}</p>
        {props.tags && <div>{props.tags.map(tag => <Chip key={tag} label={tag} />)}</div>}
        <Img fluid={props.img} />
      </a>
    </div>
  );
}
