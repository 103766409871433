import React from 'react';
import Player from '../components/player/Player';
import { useStaticQuery, graphql } from 'gatsby';

const playerData = [
  {
    name: "Bone Man",
    imgid: "boneman",
    playerid: "BoneManOfficial",
    tags: ["Dude"],
  },
  {
    name: "Knighty",
    imgid: "knighty",
    playerid: "knightyy",
    tags: ["Producer"],
  },
  {
    name: "oshiman",
    imgid: "oshiman",
    playerid: "stegokas",
    tags: ["IGL", "Rifler", "Lurker", "Coach"],
  },
  {
    name: "orTuks",
    imgid: "kowalski",
    profile: "76561199164977636",
    tags: ["Driver", "AWPer"],
  },
  {
    name: "ÖGÖ",
    imgid: "ogo",
    profile: "76561198322428656",
    tags: ["Chemist"],
  }
]

export default function PlayersContainer() {
  const images = useStaticQuery(graphql`
    query {
      boneman: file(relativePath: { eq: "boneman.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kowalski: file(relativePath: { eq: "kowalski.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      oshiman: file(relativePath: { eq: "oshiman.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      knighty: file(relativePath: { eq: "knighty.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ogo: file(relativePath: { eq: "ogo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      remymad: file(relativePath: { eq: "placeholder.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <h2 className="titleh2">
        Active Roster
      </h2>
      <div className="players-container">
        {playerData.map(data => <Player {...data} img={images[data.imgid].childImageSharp.fluid}/>)}
      </div>
      <h2 className="titleh2">
        Hall of Fame
      </h2>
      <div className="players-container">
        <Player name="Palm" img={images.remymad.childImageSharp.fluid} profile="76561198802153486" />
        <Player name="Deadpool" img={images.remymad.childImageSharp.fluid} profile="76561199009114849" />
        <Player name="hvtaja" img={images.remymad.childImageSharp.fluid} playerid="hvtaja" />
      </div>
    </>
  );
}
