import React from "react";
import "./Chip.css"; // Import the CSS file

const Chip = ({ label, onDelete }) => {
  return (
    <div className="chip">
      <span className="chip-label">{label}</span>
    </div>
  );
};

export default Chip;