import React from "react";

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";
import PlayersContainer from "../components/PlayersContainer";

import "./main.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Theority" />
    <h1 className="title">
      THEORITY
    </h1>
    <div style={{ maxWidth: '350px', margin: 'auto' }}>
      <Image />
    </div>
    <div className="summary">
      <blockquote>
        Theority on nagu pere. Me naerame koos, me võitleme koos, me nukrutseme koos ning kohe kindlasti ka võidame koos. Me hoiame alati kokku ning vahet pole kas me kaotame või võidame, me teeme seda koos. Me tahame näidata tervele Eestile milleks me võimelised oleme.
        my opinion on Theority</blockquote>
        <p className="quoter">- orTuks</p>
    </div>
    <PlayersContainer />
  </Layout >
);

export default IndexPage;
